<template>
    <b-card class="center_card" footer-class="my-footer">
      <b-card-title class="title-card-mdp mb-2">Réinitialisation de votre mot de passe</b-card-title>
      <b-card-sub-title class="sub-title mb-3">Pour réinitialiser votre mot de passe, merci de renseigner l'email associé à votre compte Applicasol.</b-card-sub-title>
      <b-row no-gutters>
        <b-col>
          <ValidationObserver ref="observer">
            <ValidationProvider name="email" :rules="{required:true, email:true}" v-slot="{errors}">
              <b-form-group
                  class="mt-2"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group >
                    <b-input-group-text variant="primary">
                      <i class="fas fa-user"></i>
                    </b-input-group-text>
                  <b-form-input
                      debounce="2500"
                      placeholder="email"
                      size="xs"
                      title='email'
                      type="email"
                      v-model="email">
                  </b-form-input>
                </b-input-group>
                <span style="color:red">{{errors[0]}}</span>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="password" :rules="{required:true,min:12, regex:new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?,£;.:!@$%&^*-]).*$/)}" v-slot="{errors}">
              <b-form-group
                  class="mt-3 mb-3"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group >
                    <b-input-group-text variant="primary">
                      <i class="fas fa-lock"></i>
                    </b-input-group-text>
                  <b-form-input
                      placeholder="mot de passe"
                      size="xs"
                      title='password'
                      :type="showPassword?'text':'password'"
                      minlength="8" required
                      v-model="password">
                  </b-form-input>
                  <b-input-group-text class="cursor-pointer" @click.prevent="toggleShow">
                    <img v-if="showPassword" src="@/assets/eye-slash-solid.svg" alt="Masquer" width="16" height="16">
                    <img v-else src="@/assets/eye-solid.svg" alt="Afficher" width="16" height="16">
                  </b-input-group-text>
                </b-input-group>

                <span style="color:red">{{errors[0]}}</span>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
                rules="required|confirmed:password"
                v-slot="{errors}" data-vv-as="password">
              <b-form-group
                  class="mt-3 mb-3"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group>
                  <b-input-group-text variant="primary">
                    <i class="fas fa-lock"></i>
                  </b-input-group-text>
                  <b-form-input placeholder="Confirmer votre mot de passe" size="xs" title='Mot de passe : '  :type="showConfirmPassword?'text':'password'"
                                v-model="mdpConfirm">
                  </b-form-input>
                  <b-input-group-text class="cursor-pointer" @click.prevent="toggleShowConfirm">
                    <img v-if="showConfirmPassword" src="@/assets/eye-slash-solid.svg" alt="Masquer" width="16" height="16">
                    <img v-else src="@/assets/eye-solid.svg" alt="Afficher" width="16" height="16">
                  </b-input-group-text>
                </b-input-group>
                <span style="color:red">{{errors[0]}}</span>
              </b-form-group>
            </ValidationProvider>

          </ValidationObserver>
        </b-col>
      </b-row>
      <div class="valider">
        <button pill @click = "reinitialiseMdp" class="btn-valid m-2">Valider</button>
      </div>
    <div slot="footer" class="p-2">
      <span class="footer-card">Copyright © 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</span>
    </div>
    </b-card>
</template>

<script>
import http from '@/http.js'
import {extend} from 'vee-validate'
import { required, regex,min,confirmed, email} from 'vee-validate/dist/rules'

extend('regex', {
  ...regex,
  message:"Le mot de passe doit contenir au moins: " +
      "une minuscule, une majuscule, " +
      "un chiffre et un caractère spécial."
});
extend("required", {
  ...required,
  message: "Ce champ est obligatoire"
});
extend('confirmed', {
  ...confirmed,
  message: "La confirmation du mot de passe ne correspond pas"
});
extend('min', {
  ...min,
  message: "Le mot de passe doit contenir au minimum 12 caractères."
});
extend('email', {
  ...email,
  message: "Le format de l'email saisi est incorrect"
});

export default {
  name:'resetPassword',

  props:{
    token:{
      required:true,
      type:String
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data(){
    return{
      password:'',
      mdpConfirm:"",
      showPassword:false,
      showConfirmPassword:false,
      email:'',
      reinitmdpData:{},
      utilisateurData: Object,
    }
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toggleShowConfirm() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async reinitialiseMdp(){
      const isValid = await this.$refs.observer.validate();
        if (isValid) {
          http.verifExistUserByEmail(this.email).then((data)=>{
            if(data === true){
              this.reinitmdpData={
                token:this.token,
                email:this.email,
                mdp:this.password
              };
              http.reinitialiseMdp(this.reinitmdpData).then((data) => {
                if (data === 'ok') {
                  this.$router.push({name: 'Login'}).then(() => {
                    this.$bvToast.toast(` Votre mot de passe est modifié`, {
                      variant: "success",
                      autoHideDelay: 5000,
                      toaster: "b-toaster-top-center",
                      noCloseButton: true,
                      solid: true
                    });
                  })
                } else {
                  this.$bvToast.toast(` Votre lien mail n'est plus valable,
              Veuillez refaire la demande de changement de mot de passe.`, {
                    variant: "danger",
                    autoHideDelay: 5000,
                    toaster: "b-toaster-top-center",
                    noCloseButton: true,
                    solid: true
                  });
                }
              })
            }else{
                this.$bvToast.toast(` l'adresse mail n'est pas reconnue`, {
                  variant: "danger",
                  autoHideDelay: 10000,
                  toaster: "b-toaster-top-center",
                  noCloseButton: true,
                  solid: true
                });
            }
          })
        }else{
          this.invalid = true;
          window.scrollTo(0, 200);
        }

    }
  },
}
</script>

<style scoped>

.error{
  color:  #f0c1c0;
  font-weight: bold;
}


</style>
